<template>
  <vx-card title="Write Off">
    <vs-tabs>
      <vs-tab label="Open">
        <div class="tab-text">
          <open></open>
        </div>
      </vs-tab>
      <vs-tab label="Waiting Approval">
        <div class="tab-text">
          <waiting-approval></waiting-approval>
        </div>
      </vs-tab>
      <vs-tab label="Waiting Execute">
        <div class="tab-text">
          <waiting-execute></waiting-execute>
        </div>
      </vs-tab>
      <vs-tab label="Confirmed">
        <div class="tab-text">
          <confirm></confirm>
        </div>
      </vs-tab>
      <vs-tab label="Canceled / Rejected">
        <div class="tab-text">
          <cancel></cancel>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open.vue";
import WaitingApproval from "./waiting-approval.vue";
import WaitingExecute from "./waiting-execute.vue";
import Confirm from "./confirm.vue";
import Cancel from "./cancel.vue";
import Reversed from "./reversed.vue";
export default {
  components: {
    open: Open,
    "waiting-approval": WaitingApproval,
    "waiting-execute": WaitingExecute,
    confirm: Confirm,
    cancel: Cancel,
    reversed: Reversed,
  },
};
</script>
